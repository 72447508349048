// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { AutoComplete, Button, Modal, message } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import InputField from 'components/commonComponents/InputField';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import SelectBox from 'components/commonComponents/SelectBox';
import TextArea from 'components/commonComponents/TextArea';

const SystemIntegrationModal = (props) => {
  const { userDetails, systemIntegration } = useSelector((store) => store.commonData);
  const { onClose, open } = props;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const { URL, type, payload = `{
  "key1": "value1",
  "key2": "value2",
} ` } = data;
  const [messageApi, contextHolder] = message.useMessage();


  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };


  const handleSubmit = async () => {

    const errors = {
      URL: !URL,
      type: !type,
      payload: !payload

    };
    setError(errors);
    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setLoading(true);

    const payload2 = {
      URL,
      type,
      payload:payload
    }

    const res = await fetchData(restAPIs.registerSystemIntegration(payload2));
    if (res.statusCode === 200) {
      systemIntegration?.dataIntegration();
      setLoading(false);
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
    } else {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  }

  const types = [
    {
      id: 'get',
      label: 'Get',
    },
    {
      id: 'post',
      label: 'Post',
    }
  ]

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className=' __pinto_dashboard'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>System Integration</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='___updt'>
            <InputField
              onChange={handleOnChange("URL")}
              value={URL}
              placeholder={'Enter your url'}
              label='URL'
              error={error.URL}
              
            />
            <div style={{marginBottom:'10px'}}>
            <SelectBox
              options={types}
              value={type}
              handleOnChange={handleOnChange('type')}
              label='Type'
            />
            </div>

            <TextArea
              label="Payload"
              placeholder="Enter JSON here"
              value={payload}
              onChange={handleOnChange('payload')}
              height={100}
            />
            <Button className='__extra_btn_cls' style={{ border: 0, color: '#fff', marginTop: '10px' }} loading={loading} onClick={() => handleSubmit()} >Submit</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SystemIntegrationModal;

SystemIntegrationModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
