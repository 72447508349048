// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { AutoComplete, Button, Modal, message } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';

const PinToDashboard = (props) => {
  const { onClose, open, title, description, questionId } = props;
  const [isLoading, setIsLoading] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [inputList, setList] = useState([])
  const [messageApi, contextHolder] = message.useMessage();
  const [inputValueCategory, setInputValueCategory] = useState('');
  const [inputListCategory, setListCategory] = useState([])


  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  const onSelectDropDown = (data, option) => {
    setInputValue(option.label);
  };

  const onChangeVal = (data, option) => {
    setInputValue(data);
  };


  const onSelectDropDownCategory = (data, option) => {
    setInputValueCategory(option.label);
  };

  const onChangeValCategory = (data, option) => {
    setInputValueCategory(data);
  };

  const handleSubmit = async () => {
    setIsLoading(true)
    const payload = {
      Qstn_id: questionId,
      title: inputValue,
      category: inputValueCategory
    }
    const res = await fetchData(restAPIs.addReport(payload));
    if (res?.statusCode === 200) {
      setIsLoading(false);
      handleClose();
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
    }
    else {
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  }

  const getListCateory = async () => {
    const res = await fetchData(restAPIs.listReport());
    if (res.statusCode === 200) {
      console.log("res", res);
      let list = []
      for (let i = 0; i < res?.body.length; i++) {
        const element = res?.body[i];
        list.push({ value: element, id: (i + 1), label: element })
      }
      setListCategory(list)
    }
  }

  useEffect(() => {
    getListCateory();
  }, [])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className=' __pinto_dashboard'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>LiLaQ-Ai</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div>
            <h4 style={{ color: '#fff', marginBottom: '6px' }}>Enter a title to display in dashboard</h4>
            <AutoComplete
              value={inputValue}
              options={inputList}
              placeholder={'Enter title to display'}
              autoFocus={true}
              className="__auto_com_serch"
              filterOption={(inputValue, option) =>
                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              onSelect={onSelectDropDown}
              onChange={onChangeVal}
            />
            <h4 style={{ color: '#fff', marginBottom: '6px' }}>Enter the category</h4>
            <AutoComplete
              value={inputValueCategory}
              options={inputListCategory}
              placeholder={'Category'}
              autoFocus={true}
              className="__auto_com_serch"
              filterOption={(inputValue, option) =>
                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              onSelect={onSelectDropDownCategory}
              onChange={onChangeValCategory}
            />
            <Button className='__extra_btn_cls' disabled={!inputValue} style={{ border: 0, color: '#fff' }} loading={isLoading} onClick={() => handleSubmit()} >Submit</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PinToDashboard;

PinToDashboard.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  questionId: PropTypes.string,
};
