// Core
import React, { useEffect, useState } from 'react';

// Styles
import './styles.scss';

// assets
import { faAdd, faArrowLeft, faArrowUpRightFromSquare, faCircleQuestion, faCubesStacked, faFilePowerpoint, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloseCircleOutlined, InboxOutlined } from '@ant-design/icons';

import UserIcon from 'assets/images/man.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip, Upload, message } from 'antd';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { LILAC_SERVER_URL } from 'config/constants';
import SelectBox from 'components/commonComponents/SelectBox';
import TimeAgo from 'react-timeago'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const HTMLPreview = ({ content }) => {
//     return <div dangerouslySetInnerHTML={{ __html: content }} />;
//   };
return (
    <div style={{ width: '100%', height: '500px' }}>
      <iframe
        src={"http://54.236.24.66:3003/" + content + ".html"}
        title="content"
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </div>
  );
  };

const Presentation = () => {
    // const { questionsSelected } = useSelector((store) => store.commonData);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const [file, setFile] = useState([]);
    const [template, setTemplate] = useState('')
    const [templateList, setTemplateList] = useState([])
    const [storyList, setStoryList] = useState([])
    const [storySelected, setStorySelected] = useState('')
    const [questionsSelected, setQuestionSelected] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [previewContent, setPreviewContent] = useState('');
    const [loadingPreview, setLoadingPreview] = useState()
    


    useEffect(() => {
        listTemplates();
        listStories();
    }, [])

    
    const handleCreatePPT = async () => {
        setLoading(true)
        let payload = {
            cards: questionsSelected,
            template: template,
            storyName: storySelected
        }
        fetch(`${LILAC_SERVER_URL}/generatePpt`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then((resp) => resp.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = `${storySelected}.pptx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);

            })
            .catch(() => alert("oh no!"));
        setLoading(false)
    }

    const removeCard = (item_se) => {
        let new_array = questionsSelected.filter(item => item !== item_se);
        dispatch({
            type: 'questionsSelected',
            value: new_array
        });
        setQuestionSelected(new_array);
    }

    const uploadProps = {
        onRemove: (file) => {
            setFile('');
        },
        beforeUpload: (file) => {
            setFile(file);
            return false;
        },
        file,
    };

    const handleOnChangeTemplate = (value) => {
        setTemplate(value);
    };

    const listTemplates = async () => {
        let payload = {}
        const res = await fetchData(restAPIs.listPptTemplates(payload));
        let pptm = []
        if (res.statusCode === 200) {
            for (let i = 0; i < res?.body.length; i++) {
                const element = res?.body[i];
                pptm.push({ id: element.templateName, label: element.templateName, ...element })
            }
            setTemplateList(pptm)
        }
    }

    const handleSubmitTemplate = async () => {
        let formd = new FormData();
        formd.append('file', file);
        const res = await fetchData(restAPIs.uploadTemplate(formd));
        if (res.statusCode === 200) {
            listTemplates();
        }
    }

    const onDragEnd = (result) => {
        // Reorder logic here
        if (!result.destination) {
            return;
        }
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const updatedItems = Array.from(questionsSelected);
        const [removedItem] = updatedItems.splice(startIndex, 1);
        updatedItems.splice(endIndex, 0, removedItem);
        dispatch({
            type: 'questionsSelected',
            value: updatedItems
        });
        setQuestionSelected(updatedItems);
    };

    const listStories = async () => {
        let payload = {}
        const res = await fetchData(restAPIs.listStory(payload));
        let pptm = []
        if (res.statusCode === 200) {
            for (let i = 0; i < res?.body.length; i++) {
                const element = res?.body[i];
                pptm.push({ id: element, label: element })
            }
            setStoryList(pptm)
            if (res?.body.length > 0) {
                handleOnChangeStory(res?.body[0])
            }
        }
    }
    const fetchStoryPreview = async (value) => {
        setLoadingPreview(true);
        console.log(value)
        let payload = {
            "storyName": value
        };
        try {
            const res = await fetchData(restAPIs.fetchStoryPreview(payload));
            setLoadingPreview(false);
            return res;
        } catch (error) {
            console.error("Error fetching story preview:", error);
            setLoadingPreview(false);
            return null;
        }
    };

    const handleOnChangeStory = async (value) => {
        setStorySelected(value);
        setLoadingPreview(true);
        setPreviewContent('');
    
        let payload = {
            "storyName": value
        }
        const res = await fetchData(restAPIs.fetchStoryPreview(payload));
        
        if (res) {
            setPreviewContent(res); // Assuming the HTML content is in the 'body' field
        } else {
            setPreviewContent('');
        }
        setLoadingPreview(false);
    };
            
    
    


    const handleUpdateOrder = async () => {
        let payload = {
            "updatedOrder": questionsSelected
        }
        const res = await fetchData(restAPIs.updateStory(payload));
        if (res.statusCode === 200) {
            messageApi.open({
                type: 'success',
                content: res?.message,
            });
        }

    };
    return (
        <>
            <div className='__presentation'>
                <div className='__common_header_top'>
                    <div style={{ maxWidth: '70%' }}>
                        <h1>My Stories</h1>
                    </div>
                    {/* <div className='__btns_'>
                        <button onClick={() => navigate('/home')}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    </div> */}
                </div>
                {contextHolder}
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='__template_sec' style={{ width: '75%', marginTop: '30px', marginBottom: '30px' }}>
                        <SelectBox
                            options={storyList}
                            value={storySelected}
                            handleOnChange={handleOnChangeStory}
                            label='Choose a story'
                        />
                        {previewContent && (
                        <div className="__story_preview" style={{ width: '132%', marginTop: '20px', color: 'white' }}>

                            <br/>
                            <h3>Story Preview
                            </h3>
                            <div className="__preview_content" style={{ border: '1px solid #ccc', padding: '10px', maxHeight: '700px', width: '100%', overflowY: 'auto' }}>
                                <HTMLPreview content={storySelected} />
                            </div>
                        </div>
                    )}
                    </div>
                    <div className='__template_sec' style={{ textAlign: 'right', width: '20%', marginTop: '30px', marginBottom: '30px' }}>
                        <SelectBox
                            options={templateList}
                            value={template}
                            handleOnChange={handleOnChangeTemplate}
                            label='Choose template'
                        />
                        <Button type="primary" className="__extra_btn_cls" loading={loading} onClick={() => handleCreatePPT()} style={{ marginTop: '15px', height: '45px' }}>Download as PPT</Button>
                    </div>
                    {/* {questionsSelected && questionsSelected.length > 0 && <div className='__template_sec'>
                        <>
                            <div style={{ fontSize: '12px', marginBottom: '10px' }}>Upload Template</div>
                            <div style={{ display: 'flex', gap: '10px', alignItems: "baseline" }}>
                                <Upload {...uploadProps} maxCount={1} multiple={false} >
                                    <Button className="__extra_btn_cls_upload_present" icon={<InboxOutlined />}>Select template to upload</Button>
                                </Upload>
                                <Button className="__extra_btn_cls" style={{ marginTop: '10px', borderColor: '#ffffff63', width: '100px' }} onClick={() => handleSubmitTemplate()}>Upload</Button>
                            </div>
                        </>
                    </div>} */}
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    {/* {questionsSelected && questionsSelected.length > 0 ?
                        <Button className="__extra_btn_cls" style={{ marginTop: '00px', marginBottom: '0px', borderColor: '#ffffff63' }} onClick={() => handleUpdateOrder()}>Update Order</Button> : ''}


                    {questionsSelected && questionsSelected.length > 0 ? <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}> */}


                        {/* <div className='__template_sec' style={{ width: '240px' }}>
                            <SelectBox
                                options={templateList}
                                value={template}
                                handleOnChange={handleOnChangeTemplate}
                                label='Choose template'
                            />
                        </div>

                        <div className='__subt_btn'>
                             <Button type="primary" className="__extra_btn_cls" loading={loading} onClick={() => handleCreatePPT()} style={{ marginTop: '15px', height: '45px' }}>Download as PPT</Button>
                        </div> */}
                    {/* </div> : ""} */}

                </div>

                {/* <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided) => (
                            <div className="__presentation_card_list" ref={provided.innerRef} {...provided.droppableProps}>
                                {questionsSelected && questionsSelected.map((item, idx) => (
                                    <Draggable key={idx} draggableId={idx.toString()} index={idx}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="__grid_item">
                                                <div className="__presentation_single_card">
                                                    <div className="__head">
                                                        <Tooltip title={item.Question}><FontAwesomeIcon icon={faCircleQuestion} /></Tooltip>
                                                        <CloseCircleOutlined onClick={() => removeCard(item)} style={{ cursor: 'pointer' }} />
                                                    </div>
                                                    <div className="__content">
                                                        {/* <h1>{item.storyName}</h1> */}
                                                        {/* <h4>{item.Question}</h4>
                                                        {item.ProcessedTime && <p><TimeAgo date={item.ProcessedTime} /></p>}
                                                    </div>
                                                    <div className="__footer"></div>
                                                </div>
                                                <button className="__add_icon">+</button>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext> */} 
            </div>
        </>
    );
};

export default Presentation;
