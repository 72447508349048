export const BASE_URL = 'https://108f-103-175-89-118.ngrok-free.app/'
export const IFRAME_URL = 'https://108f-103-175-89-118.ngrok-free.app/interactive'

export const LILAC_SERVER_URL = 'https://server.bank.lilaq.ai'
export const ORANGE_SERVER_URL = 'https://orange.bank.lilaq.ai'
export const GREY_SERVER_URL = 'https://grey.bank.lilaq.ai'

export const LILBOT_URL = 'http://98.70.73.24:8065/hooks/b9wn7fpzabre7mjtnjb9oy43by'


