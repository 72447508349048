// Core
import React, { useEffect, useState } from 'react';
// Styles
import './styles.scss';
// Assets
import UserIcon from 'assets/images/man.png'
import Unicorn from 'assets/images/unicorn_logo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faStar, faSun } from '@fortawesome/free-solid-svg-icons';
import { notification, Popconfirm, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { signOut } from 'aws-amplify/auth'; // Replace with the correct import path for signOut
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';


const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message,
        description,
    });
};

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { userDetails, domainName } = useSelector((store) => store.commonData);
    const confirmLogOut = (e) => {
        console.log(e);
        dispatch({
            type: "userDetails",
            value: "",
        });
        dispatch({
            type: "domainName",
            value: null,
        });
        localStorage.setItem("token", '');
        localStorage.setItem("userDetails", "");
        localStorage.setItem("domainName", "");
        localStorage.setItem("userPermissions", "");
        localStorage.setItem("userSettingsShape", "");

        dispatch({
            type: "userPermissions",
            value: null,
        });

        dispatch({
            type: "userSettingsShape",
            value: null,
        });
        navigate('/login')

    };


    const handleLogout = async () => {
        try {
            setLoading(true);
            await signOut();
            console.log('User signed out successfully');
            openNotificationWithIcon('success', 'User signed out successfully');
            dispatch({
                type: "userDetails",
                value: "",
            });
            localStorage.setItem("token", '');
            localStorage.setItem("userDetails", "");
            localStorage.setItem("userGroups", "");
            localStorage.setItem("userPermissions", "");
            localStorage.setItem("userSettingsShape", "");
            dispatch({
                type: "domainName",
                value: null,
            });
            dispatch({
                type: "userPermissions",
                value: null,
            });

            dispatch({
                type: "userSettingsShape",
                value: null,
            });
            localStorage.setItem("domainName", "");
            navigate('/login')
        } catch (error) {
            openNotificationWithIcon('error', 'Error signing out', error.message);
            console.error('Error signing out:', error);
        } finally {
            setLoading(false);
        }
    };


    const handlePermissions = async () => {
        const payload = {
            username: userDetails
        }
        const res = await fetchData(restAPIs.fetchUserPermissions(payload));
        if (res?.statusCode === 200) {
            let permission = res?.body?.userAccess?.length > 0 ? res?.body?.userAccess : ["register_data", "view_ql", "tune_ql", "create_model", "access_data_garage"]
            dispatch({
                type: "userPermissions",
                value: permission,
            });
            localStorage.setItem("userPermissions", permission);
        
            dispatch({
                type: "userSettingsShape",
                value: res?.body?.userPermissions.length === 0 ? res?.body?.allRoles : res?.body?.userPermissions,
            });
            localStorage.setItem("userSettingsShape", res?.body?.userPermissions.length === 0 ? JSON.stringify(res?.body?.allRoles) : JSON.stringify(res?.body?.userPermissions));
        }
        // else if(res?.statusCode === 500){
        //     let permission = ["register_data", "view_ql", "tune_ql", "create_model", "access_data_garage"]
        //     dispatch({
        //         type: "userPermissions",
        //         value: permission,
        //     });
        //     localStorage.setItem("userPermissions", permission);

        //     dispatch({
        //         type: "userSettingsShape",
        //         value: res?.body?.userPermissions,
        //     });
        //     localStorage.setItem("userSettingsShape", res?.body?.userPermissions);
        // }

        else {
            console.log("errr", res.message);
        }
    }

    useEffect(() => {
        const localDetails = localStorage.getItem("userDetails");
        const localDomain = localStorage.getItem("domainName");
        if (!userDetails && localDetails) {
            dispatch({
                type: "userDetails",
                value: JSON.parse(localDetails),
            });
            dispatch({
                type: "domainName",
                value: JSON.parse(localDomain),
            });
            handlePermissions();
        }

    }, [])

    useEffect(() => {
        handlePermissions();
    }, [userDetails])

    const cancel = (e) => {
        console.log(e);
    }

    // 
    return (
        <>
            <header className='__header'>
                <div className='__logo'>
                    <button style={{ cursor: 'pointer' }} onClick={() => navigate('/query-analyzer')}>
                        LiLaQ-Ai
                        <div>
                            <img src={Unicorn} alt="" className='__unicorn' />
                        </div>
                        {/* <div>🦄</div> */}
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 1024 1024" version="1.1"><path d="M602.24 246.72a17.28 17.28 0 0 0-11.84-16.32l-42.88-14.4A90.56 90.56 0 0 1 490.24 160l-14.4-42.88a17.28 17.28 0 0 0-32 0L428.8 160a90.56 90.56 0 0 1-57.28 57.28l-42.88 14.4a17.28 17.28 0 0 0 0 32l42.88 14.4a90.56 90.56 0 0 1 57.28 57.28l14.4 42.88a17.28 17.28 0 0 0 32 0l14.4-42.88a90.56 90.56 0 0 1 57.28-57.28l42.88-14.4a17.28 17.28 0 0 0 12.48-16.96z m301.12 221.76l-48.32-16a101.44 101.44 0 0 1-64-64l-16-48.32a19.2 19.2 0 0 0-36.8 0l-16 48.32a101.44 101.44 0 0 1-64 64l-48.32 16a19.2 19.2 0 0 0 0 36.8l48.32 16a101.44 101.44 0 0 1 64 64l16 48.32a19.2 19.2 0 0 0 36.8 0l16-48.32a101.44 101.44 0 0 1 64-64l48.32-16a19.2 19.2 0 0 0 0-36.8z m-376.64 195.52l-64-20.8a131.84 131.84 0 0 1-83.52-83.52l-20.8-64a25.28 25.28 0 0 0-47.68 0l-20.8 64a131.84 131.84 0 0 1-82.24 83.52l-64 20.8a25.28 25.28 0 0 0 0 47.68l64 20.8a131.84 131.84 0 0 1 83.52 83.84l20.8 64a25.28 25.28 0 0 0 47.68 0l20.8-64a131.84 131.84 0 0 1 83.52-83.52l64-20.8a25.28 25.28 0 0 0 0-47.68z"></path></svg>
                            {domainName?.darkmaterDomain}
                        </span>
                    </button>

                </div>
                <div className='__right_settings'>
                    <Tooltip placement="right" title="Settings">
                        <NavLink to={'/settings'} end >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="white" />
                                <path d="M19.6224 10.3954L18.5247 7.7448L20 6L18 4L16.2647 5.48295L13.5578 4.36974L12.9353 2H10.981L10.3491 4.40113L7.70441 5.51596L6 4L4 6L5.45337 7.78885L4.3725 10.4463L2 11V13L4.40111 13.6555L5.51575 16.2997L4 18L6 20L7.79116 18.5403L10.397 19.6123L11 22H13L13.6045 19.6132L16.2551 18.5155C16.6969 18.8313 18 20 18 20L20 18L18.5159 16.2494L19.6139 13.598L21.9999 12.9772L22 11L19.6224 10.3954Z" stroke="white" />
                            </svg>
                        </NavLink>
                    </Tooltip>

                    <Popconfirm
                        title="Log Out"
                        description="Are you sure want to Logout?"
                        onConfirm={handleLogout}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <img src={UserIcon} alt="" style={{ cursor: 'pointer' }} className='__user' />
                    </Popconfirm>


                    {/* <button className='__language common_background'>
                        <FontAwesomeIcon icon={faGlobe} />
                        English
                    </button> */}
                    {/* <button className='__theme_switch'>
                    <span><FontAwesomeIcon icon={faSun} /></span>
                    Light
                </button> */}


                    {/* <div class="__logo"><button style="cursor: pointer;display: flex;flex-direction: column;"><div style="
    display: flex;
    justify-content: space-between;
    width: 100%;
"><span>LiLaQ</span><span>AI</span></div><span style="
    color: #fff;
">lifesciences</span></button></div> */}
                </div>
            </header>
        </>
    );
};

export default Header;
