// Core
import React, { useEffect, useState } from 'react';

// Styles
import './styles.scss';

// Assets
import { faRotateRight, faQuestionCircle, faQuestion, faEye, faPaperPlane, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EyeOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, LoadingOutlined, AudioOutlined, BorderOutlined, PhoneOutlined } from '@ant-design/icons';
import { message, Table, Button, Popconfirm, Tabs, Switch, Tooltip } from 'antd';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useDispatch } from 'react-redux';
import AgentAddEditModel from 'components/modals/AgentAddEditModel';
import GreyQuestionView from 'components/modals/GreyQuestionView';
import { useSelector } from 'react-redux';
import GreyAnswerQuickView from 'components/modals/GreyAnswerQuickView';
import InputField from 'components/commonComponents/InputField';
import SelectBox from 'components/commonComponents/SelectBox';
import { useSpeechRecognition } from 'react-speech-kit';

import useSpeechRecogintion from 'utils/useSpeechRecogintion';

import LilBotAddEditModel from 'components/modals/LilBotAddEditModel';

const { Column } = Table;
const { TabPane } = Tabs;

const Agents = () => {
    const [loading, setLoading] = useState(true);
    const { userDetails } = useSelector((store) => store.commonData);
    const [messageApi, contextHolder] = message.useMessage();
    const [paginationData, setPaginationData] = useState({
        page: 1,
        totalItems: 0
    });
    const { totalItems, page } = paginationData;
    const dispatch = useDispatch();
    const [showModelAgent, setShowModalAgent] = useState(false)
    const [showModelGrey, setShowModalGrey] = useState(false)
    const [listAgents, setListAgents] = useState([])
    const [listGreyQustions, setListGreyQuestions] = useState([]);
    const [showModalQuickView, setShowModalQuickView] = useState(false)
    const [loadingGreyQuestions, setLoadingGreyQuestions] = useState(false)
    const [showModalQuickViewGreyAnswer, setShowModalQuickViewGreyAnswer] = useState(false)
    const [isDreamMode, setIsDreamMode] = useState(false)
    const [listQuestions, setListQuestions] = useState([]);
    const [loadingQuestions, setLoadingQuestions] = useState(false)
    const [paginationDataQuestions, setPaginationDataQuestions] = useState({
        page: 1,
        totalItems: 0
    });
    const { totalItemsQsn, pageQsn } = paginationDataQuestions;
    const [listQuestionsCollab, setListQuestionsCollab] = useState([]);
    const [loadingQuestionsCollab, setLoadingQuestionsCollab] = useState(false)
    const [paginationDataQuestionsCollab, setPaginationDataQuestionsCollab] = useState({
        page: 1,
        totalItems: 0
    });
    const { totalItemsQsnCollab, pageQsnCollab } = paginationDataQuestionsCollab;
    const [question, setQuestion] = useState('')
    const [agentSelected, setAgentSelected] = useState('')
    const [listeningState, setListeningState] = useState({
        main: false,
        lilBot: false,
    });

    const { listen, listening, stop } = useSpeechRecognition({
        onResult: (result) => {
            setQuestion(result);
        },
        onError: (error) => {
            message.error(`Error: ${error.message}`);
        }
    });

    const [listActAgnts, setActiveAgents] = useState([])
    const [activeKey, setActiveKey] = useState('1');
    const [showModelLilbot, setShowModalLilbot] = useState(false)
    const [questionLilBotInput, setQuestionLilBotInput] = useState('')
    const [agentSelectedLilBotInput, setAgentSelectedLilBotInput] = useState(null)
    const [listActAgntsLilBotInput, setActiveAgentsLilBotInput] = useState([])
    const { listening: lilBotListening, startListening: startLilBotListening, stopListening: stopLilBotListening } = useSpeechRecogintion(setQuestionLilBotInput);
    const [lilboatLoading, setLilboatLoading] = useState(false)
    const [loadingLilbot, setLoadingLilBoat] = useState(false)
    const [lilbotmembers, setLilBotMembers] = useState([])

    useEffect(() => {
        getAgents();
        getGreyQuestions();
        getDreamMode();
        getQuestions();
        getQuestionsCollab();
        listActAgents();
        listActAgentsLilBotInput();
        lilbotMembers();
    }, []);

    const getAgents = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.listAgents());
        if (res?.statusCode === 200) {
            setLoading(false)
            setListAgents(res?.body)
        }
        else {
            messageApi.open({
                type: 'error',
                content: res?.message,
            });
        }
    }

    const config = {
        pagination: {
            total: totalItems,
            // current: page,
            defaultCurrent: 1,
            defaultPageSize: 8,
            showSizeChanger: false,
            // onChange: handlePageChange
        }
    }

    const handleEdit = (data) => {
        dispatch({
            type: "agentData",
            value: {
                dataSet: data,
                isEdit: true,
                getAgents
            },
        });
        setShowModalAgent(true);
    };

    const handleAddAgent = () => {
        dispatch({
            type: "agentData",
            value: { getAgents },
        });
        setShowModalAgent(true);
    }

    const handleGreyQuestion = () => {
        dispatch({
            type: "greyQuestionsRefresh",
            value: true,
        });
        setShowModalGrey(true)

    }

    const handleDelete = async (record) => {
        const payload = {
            role: record?.role,
            agentId: record?.agentId
        };
        const res = await fetchData(restAPIs.deleteAgents(payload))
        if (res?.statusCode === 200) {
            getAgents();
        }
        else {
            messageApi.open({
                type: 'error',
                content: res?.message,
            });
        }
    }

    const getGreyQuestions = async () => {
        let payload = {
            // "username": userDetails
        }

        setLoadingGreyQuestions(true)
        const res = await fetchData(restAPIs.listGreyQuestion(payload));
        if (res?.statusCode === 200) {
            setLoadingGreyQuestions(false)
            setListGreyQuestions(res?.body)
        }
        else {
            console.log("err", res);
        }
    }

    const config2 = {
        pagination: {
            total: totalItemsQsn,
            // current: page,
            defaultCurrent: 1,
            defaultPageSize: 8,
            showSizeChanger: false,
            // onChange: handlePageChange
        }
    }

    const handleView = (data) => {
        dispatch({
            type: "questionQuickView",
            value: data,
        });
        setShowModalQuickView(true)
    };

    const onChangeSwitch = async () => {
        setIsDreamMode(!isDreamMode)
        let payload = {
            dreamMode: !isDreamMode
        }
        const res = await fetchData(restAPIs.updateDreamMode(payload));
        if (res?.statusCode === 200) {
            setIsDreamMode(res?.body?.dreamMode)
        }
        else {
            console.log("err", res);
        }
    }

    const getDreamMode = async () => {

        let payload = {}
        const res = await fetchData(restAPIs.fetchDreamMode(payload));
        if (res?.statusCode === 200) {
            setIsDreamMode(res?.body?.dreamMode)
        }
        else {
            console.log("err", res);
        }
    }


    const getQuestions = async () => {
        const payload = {
            "pageNo": 1,
            "pageSize": 10,
            "triggerTimeFlag": true,
            "username": userDetails
        }
        setLoadingQuestions(true)
        const res = await fetchData(restAPIs.listQuestion(payload));
        if (res?.statusCode === 200) {
            setLoadingQuestions(false)
            const dataArray = Object.keys(res?.body).map(key => ({ key, value: res?.body[key] }));
            setListQuestions(res?.body?.recent_queries?.questions)
        }
        else {
            messageApi.open({
                type: 'error',
                content: res?.message,
            });
        }
    }

    const getQuestionsCollab = async () => {
        const payload = {
            "pageNo": 1,
            "pageSize": 10,
            "triggerTimeFlag": true,
            "username": userDetails
        }
        setLoadingQuestionsCollab(true)
        const res = await fetchData(restAPIs.getCollaborativeAnswers(payload));
        if (res?.statusCode === 200) {
            setLoadingQuestionsCollab(false)
            const dataArray = Object.keys(res?.body).map(key => ({ key, value: res?.body[key] }));
            setListQuestionsCollab(res?.body)
        }
        else {
            messageApi.open({
                type: 'error',
                content: res?.message,
            });
        }
    }

    const handleViewGeryAnswer = (data) => {
        dispatch({
            type: "questionGreyQuickView",
            value: data,
        });
        setShowModalQuickViewGreyAnswer(true)
    };


    const handleOnChange = (key) => (value) => {
        setQuestion(value);
    };

    const handleOnChangeAgent = (value) => {
        setAgentSelected(value)
    }

    const listActAgents = async () => {
        const res = await fetchData(restAPIs.listActiveAgents({}));
        let pptm = []
        if (res.statusCode === 200) {
            for (let i = 0; i < res?.body.length; i++) {
                const element = res?.body[i];

                pptm.push({ id: element.agentname, label: element.label, ...element })
            }
            setActiveAgents(pptm)
        }
    }

    const handleSubmit = async () => {
        const obj = listActAgnts && listActAgnts?.find(item => item.agentname === agentSelected)
        const payload = {
            question: obj.label + ',' + question,
            username: userDetails,
            manager: obj
        }
        const res = await fetchData(restAPIs.submitForCollaborative(payload));
        if (res?.statusCode === 200) {
            setQuestion('')
            messageApi.open({
                type: 'success',
                content: res?.message,
            });
            getQuestionsCollab();
        }
        else {
            messageApi.open({
                type: 'error',
                content: res?.message,
            });
        }
    }

    const handleCallClick = (record) => {
        setActiveKey('3');
        setAgentSelected(record?.agentname)
    }

    const handleEditLilbot = (data) => {
        dispatch({
            type: "lilBotUserData",
            value: {
                dataSet: data,
                isEdit: true,
                lilbotMembers,
                listActAgentsLilBotInput
            },
        });
        setShowModalLilbot(true);
    };

    const handleAddLilbot = () => {
        dispatch({
            type: "lilBotUserData",
            value: { lilbotMembers, listActAgentsLilBotInput },
        });
        setShowModalLilbot(true);
    }


    const handleDeleteLilBot = async (record) => {
        const payload = {
            memberId: record?.memberId
        };
        const res = await fetchData(restAPIs.deleteLilbotMember(payload))
        if (res?.statusCode === 200) {
            lilbotMembers();
            listActAgentsLilBotInput();
        }
        else {
            messageApi.open({
                type: 'error',
                content: res?.message,
            });
        }
    }


    const handleOnChangeLilBotInput = (key) => (value) => {
        setQuestionLilBotInput(value);
    };

    const handleOnChangeAgentLilBotInput = (value) => {
        console.log("valeu", value);
        setAgentSelectedLilBotInput(value)
    }

    const listActAgentsLilBotInput = async () => {
        const res = await fetchData(restAPIs.listLilbotMembers({}));
        let pptm = []
        if (res.statusCode === 200) {
            for (let i = 0; i < res?.body.length; i++) {
                const element = res?.body[i];
                pptm.push({ id: element.email, label: element.fname + ' ' + element.lname, ...element })
            }
            setActiveAgentsLilBotInput(pptm)
        }
    }

    const handleSubmitLilBotInput = async () => {
        setLilboatLoading(true)
        const payload = {
            "message": questionLilBotInput,
            "email_array": agentSelectedLilBotInput
        }
        const res = await fetchData(restAPIs.lilbot(payload));
        setLilboatLoading(false)
        // if (res?.statusCode === 200) {
        setQuestionLilBotInput('')
        setAgentSelectedLilBotInput(null)
        messageApi.open({
            type: 'success',
            content: res?.message,
        });
        // }
        // else {
        //     messageApi.open({
        //         type: 'error',
        //         content: res?.message,
        //     });
        // }
    }

    const handleChangeTab = (e) => {
        stop();
        stopLilBotListening();
        setActiveKey(e)
    }

    const lilbotMembers = async () => {
        setLoadingLilBoat(true)
        const res = await fetchData(restAPIs.listLilbotMembers({}));
        if (res?.statusCode === 200) {
            setLoadingLilBoat(false)
            setLilBotMembers(res?.body)
        }
        else {
            messageApi.open({
                type: 'error',
                content: res?.message,
            });
        }
    }

    return (
        <>
            {contextHolder}
            <div className='___logSes_wrap'>
                <div className='__common_header_top'>
                    <div style={{ maxWidth: '70%' }}>
                        <h1>Grey Workforce</h1>
                    </div>
                </div>


                <div className='___loginSession_sec_new'>
                    <Tabs className='__tabs2' tabPosition={'top'} activeKey={activeKey} onChange={(e) => handleChangeTab(e)}>
                        <TabPane tab={'Shades'} key='1'>
                            <div className='__table_sec'>
                                <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px', gap: '20px' }}>
                                    <Button type="primary" ghost style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }} className="__extra_btn_cls" icon={<PlusCircleOutlined />} onClick={() => handleAddAgent()}>Add</Button>
                                </div>
                                <Table {...config} rowKey={(record, index) => index} dataSource={listAgents} loading={loading} className="__market_table">
                                    <Column
                                        title={'Logo'}
                                        dataIndex="agentlogo"
                                        key="agentlogo"
                                        className='___col'
                                        render={(agentlogo) =>
                                            <img src={agentlogo} style={
                                                {
                                                    width: '40px',
                                                    height: '40px',
                                                    borderRadius: '40px',
                                                    objectFit: 'cover'
                                                }
                                            } />
                                        }


                                    />
                                    <Column
                                        title={'Name'}
                                        dataIndex="agentname"
                                        key="agentname"
                                        className='___col'
                                    />
                                    <Column
                                        title={'Role'}
                                        dataIndex="role"
                                        key="role"
                                        className='___col'
                                    />

                                    <Column
                                        title=' '
                                        key="action"
                                        className='___col'
                                        render={(text, record) => (
                                            <FontAwesomeIcon
                                                icon={faPhone}
                                                onClick={() => handleCallClick(record)}
                                                style={{ fontSize: "18px", color: '#fff' }}
                                            />
                                            // <div className="table-action">
                                            //   <Button type="primary" ghost style={{ color: '#fff', borderColor: '#fff3', marginTop: '0', width:'100px' }} className="__extra_btn_cls" icon={<FontAwesomeIcon icon={faPhone} />} onClick={() => handleCallClick(record)}></Button>  
                                            // </div>
                                        )}
                                    />

                                    <Column
                                        title='Actions'
                                        key="action"
                                        render={(text, record) => (
                                            <div className="table-action">
                                                <EditOutlined
                                                    onClick={() => handleEdit(record)}
                                                    style={{ fontSize: "18px", color: '#03A9F4' }}
                                                />
                                                <Popconfirm
                                                    title={`Are you sure to delete: ${record?.role}?`}
                                                    onConfirm={() => handleDelete(record)}
                                                    okText="Delete"
                                                    cancelText="No"
                                                    placement='left'
                                                >
                                                    <DeleteOutlined
                                                        style={{ fontSize: "18px", color: '#F44336' }}
                                                    />
                                                </Popconfirm>
                                            </div>
                                        )}
                                    />
                                </Table>
                            </div>
                        </TabPane>

                        <TabPane tab={'Introspections'} key='2'>
                            {loadingGreyQuestions ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '20vh' }}>
                                    <LoadingOutlined style={{ color: '#fff', fontSize: '42px' }} />
                                </div>
                                :
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px', gap: '20px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <label htmlFor="Status" style={{ fontWeight: '300', fontSize: '13px', marginRight: '8px' }}>Dream Mode</label>
                                            <Switch
                                                onChange={onChangeSwitch}
                                                checked={isDreamMode}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'end', gap: '20px' }}>
                                            <Button type="primary" ghost style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }} className="__extra_btn_cls" icon={<FontAwesomeIcon icon={faRotateRight} />} onClick={() => getGreyQuestions()}>Refresh</Button>
                                        </div>

                                    </div>

                                    <div className='____grey_qstn_sec'>
                                        {listGreyQustions.map((cardItem, idx) => {
                                            return (
                                                <div>
                                                    <div className='__grey_single'>
                                                        <img src={cardItem?.agentDetails?.agentLogo} alt="" />
                                                        <div className='__text_sec'>
                                                            <h5>{cardItem?.agentDetails?.agentName}</h5>
                                                            <span>{cardItem?.agentDetails?.role}</span>
                                                            <h2> <FontAwesomeIcon fontSize={17} style={{ marginRight: '15px' }} icon={faQuestionCircle} />{cardItem?.Question}</h2>

                                                        </div>
                                                    </div>
                                                    <div className='__grey_single __right'>
                                                        <div className='__text_sec'>
                                                            <h5 style={{ marginTop: '10px' }}>LiLaQ-Ai</h5>
                                                            <div className='__qst_ans_sec'>
                                                                {cardItem?.Status !== 'inprogress' ? <p>{cardItem?.Answer}</p>
                                                                    : <div class="loader">
                                                                        loading  <span>.</span><span>.</span><span>.</span>
                                                                    </div>
                                                                }
                                                                <div className='___edit_view'>
                                                                    {cardItem?.Status !== 'inprogress' && <button onClick={() => handleView(cardItem)}>More Details</button>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <label>L</label>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div></>}

                        </TabPane>

                        <TabPane tab={'Ask Workforce'} key='3'>
                            <div className='__new_inp_disc_sec'>
                                <SelectBox
                                    options={listActAgnts}
                                    handleOnChange={handleOnChangeAgent}
                                    // label='Select'
                                    value={agentSelected}
                                    placeholder={'Select'}
                                />
                                <InputField
                                    onChange={handleOnChange("question")}
                                    value={question}
                                    placeholder={'Type...'}
                                    marginBottom={0}
                                    className='sss'
                                />
                                <div style={{ display: 'flex', gap: '5px', }}>

                                    {!listening ?
                                        <Button type="primary" ghost style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }} className="__extra_btn_cls" icon={<AudioOutlined />} onClick={listen}></Button>
                                        :
                                        <Button type="primary" ghost style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }} className="__extra_btn_cls" icon={<BorderOutlined />} onClick={stop}></Button>
                                    }

                                    <Button type="primary" ghost style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }} className="__extra_btn_cls" icon={<FontAwesomeIcon icon={faPaperPlane} />} disabled={!question} onClick={() => handleSubmit()}>Ask</Button>
                                </div>

                            </div>



                            {!loadingQuestionsCollab && <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px', gap: '20px' }}>
                                <Button type="primary" ghost style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }} className="__extra_btn_cls" icon={<FontAwesomeIcon icon={faRotateRight} />} onClick={() => getQuestionsCollab()}>Refresh</Button>
                            </div>}
                            <>
                                <div className='__table_sec'>


                                    <Table {...config} rowKey={(record, index) => index} dataSource={listQuestionsCollab} loading={loadingQuestionsCollab} className="__market_table">
                                        <Column
                                            title={'Question'}
                                            dataIndex="Question"
                                            key="Question"
                                            className='___col'
                                            render={(item) =>
                                                <Tooltip title={item} overlayStyle={{ maxWidth: '500px' }}>
                                                    <p>{item}</p>
                                                </Tooltip>
                                            }
                                        />
                                        <Column
                                            title={'Asked by'}
                                            dataIndex="username"
                                            key="username"
                                            className='___col'
                                            render={(item) =>
                                                <Tooltip title={item} overlayStyle={{ maxWidth: '500px' }}>
                                                    <p>{item}</p>
                                                </Tooltip>
                                            }
                                        />

                                        <Column
                                            title={'Asked to'}
                                            dataIndex="Manager"
                                            key="Manager"
                                            className='___col'
                                            render={(item) =>
                                                <Tooltip title={item} overlayStyle={{ maxWidth: '500px' }}>
                                                    <p>{item}</p>
                                                </Tooltip>
                                            }
                                        />

                                        <Column
                                            title={'Status'}
                                            dataIndex="Status"
                                            key="Status"
                                            className='___col'
                                            render={(item) =>
                                                <>
                                                {
                                                    item === 'inprogress' ? 
                                                        <div className='__inprogress'>
                                                          <div className="circles">
                                                            <div className="circle1"></div>
                                                            <div className="circle2"></div>
                                                            <div className="circle3"></div>
                                                          </div>
                                                        </div>
                                                       :
                                                      <div className={`__registered ${item === 'new' ? 'new-status' : ''}`}></div>
                                                    }
                                                </>
                                            }
                                        />


                                        <Column
                                            title='View'
                                            key="action"
                                            render={(text, record) => (
                                                <div className="table-action">
                                                    <EyeOutlined
                                                        onClick={() => handleViewGeryAnswer(record)}
                                                        style={{ fontSize: "18px", color: '#03A9F4' }}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Table>
                                </div>
                            </>
                        </TabPane>

                        <TabPane tab={'LilBot Workforce'} key='4'>
                            <div className='__table_sec'>

                                <div className='__new_inp_disc_sec'>
                                    <SelectBox
                                        options={listActAgntsLilBotInput}
                                        handleOnChange={handleOnChangeAgentLilBotInput}
                                        value={agentSelectedLilBotInput}
                                        // placeholder={'Select'}
                                        multiple

                                    />
                                    <InputField
                                        onChange={handleOnChangeLilBotInput("question")}
                                        value={questionLilBotInput}
                                        placeholder={'Type...'}
                                        marginBottom={0}
                                        className='sss'
                                    />
                                    <div style={{ display: 'flex', gap: '5px', }}>
                                        {!lilBotListening ?
                                            <Button type="primary" ghost style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }} className="__extra_btn_cls" icon={<AudioOutlined />} onClick={startLilBotListening}></Button>
                                            :
                                            <Button type="primary" ghost style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }} className="__extra_btn_cls" icon={<BorderOutlined />} onClick={stopLilBotListening}></Button>
                                        }
                                        <Button loading={lilboatLoading} type="primary" ghost style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }} className="__extra_btn_cls" icon={<FontAwesomeIcon icon={faPaperPlane} />} disabled={!questionLilBotInput} onClick={() => handleSubmitLilBotInput()}>Send</Button>
                                    </div>

                                </div>

                                <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px', gap: '20px' }}>
                                    <Button type="primary" ghost style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }} className="__extra_btn_cls" icon={<PlusCircleOutlined />} onClick={() => handleAddLilbot()}>Add</Button>
                                </div>
                                <Table {...config} rowKey={(record, index) => index} dataSource={lilbotmembers} loading={loadingLilbot} className="__market_table">

                                    <Column
                                        title={'User Id'}
                                        dataIndex="userId"
                                        key="userId"
                                        className='___col'
                                    />
                                    <Column
                                        title={'First Name'}
                                        dataIndex="fname"
                                        key="fname"
                                        className='___col'
                                    />
                                    <Column
                                        title={'Last Name'}
                                        dataIndex="lname"
                                        key="lname"
                                        className='___col'
                                    />

                                    <Column
                                        title={'Email'}
                                        dataIndex="email"
                                        key="email"
                                        className='___col'
                                    />

                                    <Column
                                        title='Actions'
                                        key="action"
                                        render={(text, record) => (
                                            <div className="table-action">
                                                <EditOutlined
                                                    onClick={() => handleEditLilbot(record)}
                                                    style={{ fontSize: "18px", color: '#03A9F4' }}
                                                />
                                                <Popconfirm
                                                    title={`Are you sure to delete: ${record?.fname + ' ' +record?.lname }?`}
                                                    onConfirm={() => handleDeleteLilBot(record)}
                                                    okText="Delete"
                                                    cancelText="No"
                                                    placement='left'
                                                >
                                                    <DeleteOutlined
                                                        style={{ fontSize: "18px", color: '#F44336' }}
                                                    />
                                                </Popconfirm>

                                            </div>
                                        )}
                                    />
                                </Table>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
            <AgentAddEditModel
                open={showModelAgent}
                onClose={() => setShowModalAgent(false)}
            />
            <GreyQuestionView
                open={showModalQuickView}
                onClose={() => setShowModalQuickView(false)}
            />
            <GreyAnswerQuickView
                open={showModalQuickViewGreyAnswer}
                onClose={() => setShowModalQuickViewGreyAnswer(false)}
            />
            <LilBotAddEditModel
                open={showModelLilbot}
                onClose={() => setShowModalLilbot(false)}
            />
        </>
    );
};

export default Agents;

