// // Core
// import React, { useEffect, useState } from 'react';

// // Styles
// import './styles.scss';

// // Assets
// import { LoadingOutlined, PushpinOutlined } from '@ant-design/icons';

// import { Button, message, Tooltip } from 'antd';
// import { fetchData } from 'utils/fetch';
// import { restAPIs } from 'utils/restAPIs';
// import { useDispatch } from 'react-redux';
// import QuickView from 'components/modals/QuickView';



// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   RadialLinearScale,
//   PointElement,
//   LineElement,
//   BarElement,
//   ArcElement,
//   Title,
//   Tooltip as TooltipChart,
//   Legend,
//   Filler
// } from 'chart.js';
// import { Line, Bar, Pie, Radar, Scatter } from 'react-chartjs-2';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   RadialLinearScale,
//   PointElement,
//   LineElement,
//   BarElement,
//   ArcElement,
//   Title,
//   TooltipChart,
//   Legend,
//   Filler
// );


// const Dashboard = () => {
//   const [loading, setLoading] = useState(true);
//   const [listData, setListData] = useState([]);
//   const [messageApi, contextHolder] = message.useMessage();
//   const [data, setData] = useState('')
//   const [open, setQuickView] = useState(false)
//   const dispatch = useDispatch();
//   const [unpinLoading, setIsUnpinLoading] = useState(false)

//   const getQuestions = async () => {
//     setLoading(true);
//     const res = await fetchData(restAPIs.getReport());
//     if (res?.statusCode === 200) {
//       setLoading(false);
//       setListData(res?.body);
//     } else {
//       messageApi.open({
//         type: 'error',
//         content: res?.message,
//       });
//     }
//   };

//   useEffect(() => {
//     getQuestions();
//   }, []);

//   const handleCardClick = (item) => {
//     dispatch({
//       type: 'cardSelected',
//       value: item
//     });
//     setData(item)
//     setQuickView(true)
//   }



//   // const TableComponent = ({ data }) => {
//   //   if (!data || data.length === 0) {
//   //     return <div>No data available</div>;
//   //   }

//   //   // Flatten the data
//   //   const flattenedData = [];
//   //   const keys = Object.keys(data[0]);
//   //   const length = Object.values(data[0][keys[0]]).length;

//   //   for (let i = 0; i < length; i++) {
//   //     const row = {};
//   //     keys.forEach(key => {
//   //       row[key] = data[0][key][i];
//   //     });
//   //     flattenedData.push(row);
//   //   }

//   //   // Extract column headers from the first object in the flattened array
//   //   const headers = Object.keys(flattenedData[0]);

//   //   return (
//   //     <table>
//   //       <thead>
//   //         <tr>
//   //           {headers.map((header, index) => (
//   //             <th key={index}>{header}</th>
//   //           ))}
//   //         </tr>
//   //       </thead>
//   //       <tbody>
//   //         {flattenedData.map((row, rowIndex) => (
//   //           <tr key={rowIndex}>
//   //             {headers.map((header, colIndex) => (
//   //               <td key={colIndex}>{row[header]}</td>
//   //             ))}
//   //           </tr>
//   //         ))}
//   //       </tbody>
//   //     </table>
//   //   );
//   // };



//   const TableComponent = ({ data }) => {
//     const headers = Object.keys(data[0]);

//     return (
//       <table>
//         <thead>
//           <tr>
//             {headers.map((header, index) => (
//               <th key={index}>{header}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((row, rowIndex) => (
//             <tr key={rowIndex} >
//               {headers.map((header, colIndex) => (
//                 <td key={colIndex}><Tooltip title={row[header]}><span className='__single_line'>{row[header]}</span></Tooltip></td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     );
//   };



//   const handleUnPin = async (item) => {
//     setIsUnpinLoading(true)
//     const res = await fetchData(restAPIs.unPin({ Qstn_id: item?.Qstnid }));
//     if (res?.statusCode === 200) {
//       messageApi.open({
//         type: 'success',
//         content: res?.message,
//       });
//       getQuestions();
//     } else {
//       messageApi.open({
//         type: 'error',
//         content: res?.message,
//       });
//     }
//   }



//   return (
//     <>
//       {contextHolder}
//       <div className='__common_header_top'>
//                     <div style={{ maxWidth: '70%' }}>
//                         <h1 style={{marginBottom:'20px'}}>My Insights</h1>
//                     </div>
//                     {/* <div className='__btns_'>
//                         <button onClick={() => navigate('/home')}><FontAwesomeIcon icon={faArrowLeft} /></button>
//                     </div> */}
//                 </div>
//       {loading ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '20vh' }}> <LoadingOutlined style={{ color: '#fff', fontSize: '42px' }} /></div> : <div className='___dsh_wrap'>
      
//         <div className='___dashboard_sec_new'>
//           <div className='__tile_sect'>
//             {listData && listData.map((item, idx) => {
//               const key = Object.keys(item)[0]; // Get the key of the item
//               const value = item[key]; // Get the value object

//               return (
//                 <div className='__single_tile_card' key={idx}>
//                   <div className='___cat_hd'><span className='__mm_key'>{key}</span></div>
//                   {value?.tile && <div className='__tile_griding'>
//                     {value?.tile && value.tile.map((tileItem, tileIdx) => (
//                       <div key={tileIdx} className='__sing' onClick={() => handleCardClick(tileItem)}>
//                         <h5>{tileItem?.Title}</h5>
//                         <h1 style={{ fontSize: '1rem' }}>{tileItem?.tile_value}</h1>
//                       </div>
//                     ))}
//                   </div>}
//                   <div className='__tile_griding'>
//                     {value?.table && value?.table.map((tableItem, tableIdx) => (
//                       <div key={tableIdx} className='__sing'>
//                         <div className='__hdr_cd_edit'>
//                           <h5>{tableItem?.Title}</h5>
//                           <div className='___actions'>
//                             <Button style={{ borderColor: '#ffffff1f' }} ghost icon={<PushpinOutlined />} onClick={()=>handleUnPin(tableItem)}></Button>
//                             <Button ghost icon={<FontAwesomeIcon icon={faMagnifyingGlassPlus} />} onClick={() => handleCardClick(tableItem)} style={{ borderColor: '#ffffff1f' }} ></Button>
//                           </div>
//                         </div>


//                         <div id="chart4" style={{ color: '#fff'}}>
//                           {tableItem?.ChartType === 'radar' && <Radar data={tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.data} options={{
//                             ...tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.options, scales: {
//                               x: {
//                                 grid: {
//                                   display: false,
//                                 },
//                                 ticks: {
//                                   color: '#ffffffc2',
//                                 },
//                               },
//                               y: {
//                                 grid: {
//                                   display: false,
//                                 },
//                                 ticks: {
//                                   color: '#ffffffc2',
//                                 },
//                               },
//                             },
//                             color: '#ffffff',
//                           }} />}
//                           {tableItem?.ChartType === 'pie' &&
//                             <Pie
//                               data={{
//                                 ...tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.data,
//                               }}

//                               options={{
//                                 ...tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.options, scales: {
//                                   x: {
//                                     grid: {
//                                       display: false,
//                                     },
//                                     ticks: {
//                                       color: '#ffffffc2',
//                                     },
//                                   },
//                                   y: {
//                                     grid: {
//                                       display: false,
//                                     },
//                                     ticks: {
//                                       color: '#ffffffc2',
//                                     },
//                                   },
//                                 },
//                                 color: '#ffffff',
//                                 plugins: {
//                                   legend: {
//                                     display:false,
//                                     position: 'bottom',
//                                     labels: {
//                                       boxWidth: 40, // Adjust the box 
//                                       borderRadius: 10,
//                                       borderWidth: 1,
//                                       font: {
//                                         size: 12, // Adjust the font size
//                                       },
//                                     },
//                                   },
//                                 },
//                               }} />}
//                           {tableItem?.ChartType === 'line' && <Line data={tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.data} options={{
//                             ...tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.options, scales: {
//                               x: {
//                                 grid: {
//                                   display: false,
//                                 },
//                                 ticks: {
//                                   color: '#ffffffc2',
//                                 },
//                               },
//                               y: {
//                                 grid: {
//                                   display: false,
//                                 },
//                                 ticks: {
//                                   color: '#ffffffc2',
//                                 },
//                               },
//                             },
//                             color: '#ffffff',
//                           }} />}
//                           {tableItem?.ChartType === 'bar' && <Bar data={tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.data} options={{
//                             ...tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.options, scales: {
//                               x: {
//                                 grid: {
//                                   display: false,
//                                 },
//                                 ticks: {
//                                   color: '#ffffffc2',
//                                   callback: function(value, index, values) {
                                    
//                                     const label = this.getLabelForValue(value);
//                                     console.log("vale", label);
//                                     const shortenedLabel = label.length > 10 ? label.substring(0, 10) + '...' : label;
//                                     return (shortenedLabel);
//                                   }
//                                 },
//                               },
//                               y: {
//                                 grid: {
//                                   display: false,
//                                 },
//                                 ticks: {
//                                   color: '#ffffffc2',
//                                   callback: function(value, index, values) {
//                                     const label = this.getLabelForValue(value);
//                                     const shortenedLabel = label.length > 10 ? label.substring(0, 10) + '...' : label;
//                                     return (shortenedLabel);
//                                   }
//                                 },
//                               },
//                             },
//                             color: '#ffffff',
                            
//                           }} />}
//                           {tableItem?.ChartType === 'area' && <Line data={tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.data} options={{
//                             ...tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.options,
//                             scales: {
//                               x: {
//                                 grid: {
//                                   display: false,
//                                 },
//                                 ticks: {
//                                   color: '#ffffffc2',
//                                 },
//                               },
//                               y: {
//                                 grid: {
//                                   display: false,
//                                 },
//                                 ticks: {
//                                   color: '#ffffffc2',
//                                 },
//                               },
//                             },
//                             plugins: {
//                               filler: {
//                                 propagate: true
//                               },
//                             },
//                             color: '#ffffff',
//                             fill: true
//                           }} />}
//                           {tableItem?.ChartType === 'scatter' && <Scatter data={tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.data} options={{
//                             ...tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.options, scales: {
//                               x: {
//                                 grid: {
//                                   display: false,
//                                 },
//                                 ticks: {
//                                   color: '#ffffffc2',
//                                 },
//                               },
//                               y: {
//                                 grid: {
//                                   display: false,
//                                 },
//                                 ticks: {
//                                   color: '#ffffffc2',
//                                 },
//                               },
//                             },
//                             color: '#ffffff',
//                           }} />}
//                           {tableItem?.ChartType === 'table' && <TableComponent data={tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.parsedData} />}
//                         </div>


//                         {/* {tableItem?.ImageString && 
//                                                 <img className='imageLoader' src={tableItem?.ImageString} alt="" style={{ width: '100%', minHeight: '170px', maxHeight: '230px', borderRadius: '10px', objectFit: 'cover' }} />} */}
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               );
//             })}
//             <div></div>
//             <div></div>
//             <div></div>
//           </div>
//         </div>
//       </div>}
//       <QuickView
//         title={data?.Title}
//         description={data?.displayText}
//         Image={data?.ImageString}
//         Qstn_id={data?.Qstnid}
//         Question={data?.Question}
//         open={open}
//         onClose={() => setQuickView(false)}
//         chartJSON={data?.chartJSON || "{}"}
//       />
//     </>
//   );
// };

// export default Dashboard;


import React, { useEffect, useState } from 'react';
import './styles.scss';
import { LoadingOutlined, PushpinOutlined } from '@ant-design/icons';
import { Button, message, Tooltip } from 'antd';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useDispatch } from 'react-redux';
import QuickView from 'components/modals/QuickView';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  Filler
} from 'chart.js';
import { Line, Bar, Pie, Radar, Scatter } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  TooltipChart,
  Legend,
  Filler
);

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState('')
  const [open, setQuickView] = useState(false)
  const dispatch = useDispatch();
  const [unpinLoading, setIsUnpinLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)

  const getQuestions = async () => {
    setLoading(true);
    const res = await fetchData(restAPIs.getReport());
    if (res?.statusCode === 200) {
      setLoading(false);
      setListData(res?.body);
      setIsEmpty(res?.body?.length === 0 ? true : false)
    } else {
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  const handleCardClick = (item) => {
    dispatch({
      type: 'cardSelected',
      value: item
    });
    setData(item)
    setQuickView(true)
  }

  const TableComponent = ({ data }) => {
    const headers = Object.keys(data[0]);

    return (
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, colIndex) => (
                <td key={colIndex}><Tooltip title={row[header]}><span className='__single_line'>{row[header]}</span></Tooltip></td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const handleUnPin = async (item) => {
    setIsUnpinLoading(true)
    const res = await fetchData(restAPIs.unPin({ Qstn_id: item?.Qstnid }));
    if (res?.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
      getQuestions();
    } else {
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  }

  return (
    <>
      {contextHolder}
      <div className='__common_header_top'>
        <div style={{ maxWidth: '70%' }}>
          <h1 style={{ marginBottom: '20px' }}>My Insights</h1>
        </div>
      </div>
      {loading ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '20vh' }}> <LoadingOutlined style={{ color: '#fff', fontSize: '42px' }} /></div> : <div className='___dsh_wrap'>
        <div className='___dashboard_sec_new'>
          <div className='__tile_sect'>
            {listData && listData.map((item, idx) => {
              const key = Object.keys(item)[0]; // Get the key of the item
              const value = item[key]; // Get the value object

              return (
                <div className='__single_tile_card' key={idx}>
                  <div className='___cat_hd'><span className='__mm_key'>{key}</span></div>
                  {value?.tile && <div className='__tile_griding'>
                    {value?.tile && value.tile.map((tileItem, tileIdx) => (
                      <Tooltip title={tileItem?.Title} key={tileIdx}>
                        <div key={tileIdx} className='__sing' onClick={() => handleCardClick(tileItem)}>
                          <h5>{tileItem?.Title}</h5>
                          <h1 style={{ fontSize: '1rem' }}>{tileItem?.tile_value}</h1>
                        </div>
                      </Tooltip>
                    ))}
                  </div>}
                  <div className='__tile_griding'>
                    {value?.table && value?.table.map((tableItem, tableIdx) => (
                      <div key={tableIdx} className='__sing'>
                        <div className='__hdr_cd_edit'>
                          <h5>{tableItem?.Title}</h5>
                          <div className='___actions'>
                            <Button style={{ borderColor: '#ffffff1f' }} ghost icon={<PushpinOutlined />} onClick={() => handleUnPin(tableItem)}></Button>
                            <Button ghost icon={<FontAwesomeIcon icon={faMagnifyingGlassPlus} />} onClick={() => handleCardClick(tableItem)} style={{ borderColor: '#ffffff1f' }} ></Button>
                          </div>
                        </div>

                        <div id="chart4" style={{ color: '#fff' }}>
                          {tableItem?.ChartType === 'radar' && <Radar data={tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.data} options={{
                            ...tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.options, scales: {
                              x: {
                                grid: {
                                  display: false,
                                },
                                ticks: {
                                  color: '#ffffffc2',
                                },
                              },
                              y: {
                                grid: {
                                  display: false,
                                },
                                ticks: {
                                  color: '#ffffffc2',
                                },
                              },
                            },
                            color: '#ffffff',
                          }} />}
                          {tableItem?.ChartType === 'pie' &&
                            <Pie
                              data={{
                                ...tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.data,
                              }}
                              options={{
                                ...tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.options, scales: {
                                  x: {
                                    grid: {
                                      display: false,
                                    },
                                    ticks: {
                                      color: '#ffffffc2',
                                    },
                                  },
                                  y: {
                                    grid: {
                                      display: false,
                                    },
                                    ticks: {
                                      color: '#ffffffc2',
                                    },
                                  },
                                },
                                color: '#ffffff',
                                plugins: {
                                  legend: {
                                    display: false,
                                    position: 'bottom',
                                    labels: {
                                      boxWidth: 40,
                                      borderRadius: 10,
                                      borderWidth: 1,
                                      font: {
                                        size: 12,
                                      },
                                    },
                                  },
                                },
                              }} />}
                          {tableItem?.ChartType === 'line' && <Line data={tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.data} options={{
                            ...tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.options, scales: {
                              x: {
                                grid: {
                                  display: false,
                                },
                                ticks: {
                                  color: '#ffffffc2',
                                },
                              },
                              y: {
                                grid: {
                                  display: false,
                                },
                                ticks: {
                                  color: '#ffffffc2',
                                },
                              },
                            },
                            color: '#ffffff',
                          }} />}
                          {tableItem?.ChartType === 'bar' && <Bar data={tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.data} options={{
                            ...tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.options, scales: {
                              x: {
                                grid: {
                                  display: false,
                                },
                                ticks: {
                                  color: '#ffffffc2',
                                  callback: function (value) {
                                    const label = this.getLabelForValue(value);
                                    const shortenedLabel = label.length > 10 ? label.substring(0, 10) + '...' : label;
                                    return shortenedLabel;
                                  }
                                },
                              },
                              y: {
                                grid: {
                                  display: false,
                                },
                                ticks: {
                                  color: '#ffffffc2',
                                  callback: function (value) {
                                    const label = this.getLabelForValue(value);
                                    const shortenedLabel = label.length > 10 ? label.substring(0, 10) + '...' : label;
                                    return shortenedLabel;
                                  }
                                },
                              },
                            },
                            color: '#ffffff',
                          }} />}
                          {tableItem?.ChartType === 'area' && <Line data={tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.data} options={{
                            ...tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.options,
                            scales: {
                              x: {
                                grid: {
                                  display: false,
                                },
                                ticks: {
                                  color: '#ffffffc2',
                                },
                              },
                              y: {
                                grid: {
                                  display: false,
                                },
                                ticks: {
                                  color: '#ffffffc2',
                                },
                              },
                            },
                            plugins: {
                              filler: {
                                propagate: true
                              },
                            },
                            color: '#ffffff',
                            fill: true
                          }} />}
                          {tableItem?.ChartType === 'scatter' && <Scatter data={tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.data} options={{
                            ...tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.options, scales: {
                              x: {
                                grid: {
                                  display: false,
                                },
                                ticks: {
                                  color: '#ffffffc2',
                                },
                              },
                              y: {
                                grid: {
                                  display: false,
                                },
                                ticks: {
                                  color: '#ffffffc2',
                                },
                              },
                            },
                            color: '#ffffff',
                          }} />}
                          {tableItem?.ChartType === 'table' && <TableComponent data={tableItem?.ChartJSON && JSON.parse(tableItem?.ChartJSON)?.parsedData} />}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>}

      {isEmpty && <span style={{color:'#d1d1d1'}}>No Insights pinned here !</span>}
      <QuickView
        title={data?.Title}
        description={data?.displayText}
        Image={data?.ImageString}
        Qstn_id={data?.Qstnid}
        Question={data?.Question}
        open={open}
        onClose={() => setQuickView(false)}
        chartJSON={data?.chartJSON || "{}"}
      />
    </>
  );
};

export default Dashboard;
