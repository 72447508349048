export const USER_DETAILS = 'userDetails';
export const QUESTIONS_SELECTED = 'questionsSelected'
export const SUBSCRIPTION_SELECTED = 'subscriptionSelected'
export const CARD_SELECTED = 'cardSelected'
export const SIMILAR_QUESTION_TBLSL = 'similarQuestionTableItem'
export const DATAMART = 'dataMartItem'
export const ACTIVE_DATAMART = 'activeDataMart'
export const ACTIVE_MODELSET = 'activeModelSet'
export const DATAMART_NAME = 'datamartName'
export const AGENT_DATA = 'agentData'
export const QUESTION_QUICKVIEW = 'questionQuickView'
export const SET_DATA_SCIENTIST_VALUE = 'setDataScientistValue'
export const GREY_QUESTION_REFRESH = 'greyQuestionsRefresh'
export const URL_GENERATED = 'urlGenerated'
export const GERY_QUESTION_VIEW = 'questionGreyQuickView'
export const DOMAIN_NAME = 'domainName'
export const LILBOT_USER_DATA = 'lilBotUserData'
export const SYSTEM_INTEGRATION = 'systemIntegration'
export const SHADE_DATA = 'shadeData'
export const USER_PERMISSIONS = 'userPermissions'
export const USER_SETTINGS_SHAPE = 'userSettingsShape'