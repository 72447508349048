// Core
import React from 'react';
import "App.css"
// Components
import AppRouter from 'routes';
// Style
import 'assets/styles/globalstyles.scss';
import awsconfig from 'aws-exports';
import { Amplify } from 'aws-amplify';
Amplify.configure(awsconfig);


const App = () => {
  return <div className='grd_outer'>
    <div className='grd'>
      <AppRouter />
    </div>
  </div>;
};

export default App;
