// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Button, Modal, Upload, notification,Switch } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import InputField from 'components/commonComponents/InputField';
import { useDispatch, useSelector } from 'react-redux';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import { faClose, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectBox from 'components/commonComponents/SelectBox';

const openNotificationWithIcon = (title, message) => {
  notification["error"]({
    message: title,
    description: message,
  });
};

const UploadDataMart = (props) => {
  const { onClose, open, title, description } = props;
  const { userDetails, dataMartItem } = useSelector((store) => store.commonData);
  const [data, setData] = useState({});
  const [file, setFile] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('aws')
  const [toggle,setToggle] = useState(true)
  
  const {datamartList}  = dataMartItem
  
  const handleUpload = async () => {
    if (type === 'local') {
      const errors = {
        name: !name,
        file: !file
      };
      setError(errors);
      const isFormValid = Object.values(errors).every((item) => !item);
      if (!isFormValid) return;

      setLoading(true);
      let formd = new FormData();
      formd.append('file', file);
      formd.append('username', userDetails);
      formd.append('datasetName', name);
      formd.append('mode', 'local');
      formd.append("relation", toggle ? "True" : "False");
      formd.append('type', 'raw data');

      const formDataObject = {};
      for (let key of formd.keys()) {
        formDataObject[key] = formd.get(key);
      }

      // Log the plain object to the console
      const res = await fetchData(restAPIs.uploadMeta(formd));
      if (res.statusCode === 200) {
        setLoading(false);
        datamartList();
        onClose();
      } else {
        setLoading(false);
        openNotificationWithIcon(res?.message);
      }
    }
    if (type === 'aws') {
      const errors = {
        name: !name,
        path: !path
      };
      setError(errors);
      const isFormValid = Object.values(errors).every((item) => !item);
      if (!isFormValid) return;

      setLoading(true);
      let formd = new FormData();
      formd.append('awspath', path);
      formd.append('username', userDetails);
      formd.append('datasetName', name);
      formd.append('mode', 's3');
      formd.append("relation", toggle ? true : false);

      const formDataObject = {};
      for (let key of formd.keys()) {
        formDataObject[key] = formd.get(key);
      }

      // Log the plain object to the console
      const res = await fetchData(restAPIs.uploadMeta(formd));
      if (res.statusCode === 200) {
        setLoading(false);
        datamartList();
        onClose();
      } else {
        setLoading(false);
        openNotificationWithIcon(res?.message);
      }
    }

  };

  const uploadProps = {

    onRemove: (file) => {
      setFile('');
    },
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
    file,
  };

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };

  const { name, path } = data;

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setFile('');
      setData({})
      onClose();
    }
  };
  const handleToggle = () => {
    setToggle(!toggle);
  };
  const typeList = [
    {
      id: 'aws',
      label: 'AWS S3'
    },
    {
      id: 'local',
      label: 'Local'
    }
  ]

  const handleOnChangeType = (value) => {
    setType(value);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __height_reduced'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Register Your Data</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='.____common_model_class_desc'>

            <SelectBox
              options={typeList}
              value={type}
              handleOnChange={handleOnChangeType}
              label='Choose type'
            />

            <div style={{ marginTop: '20px' }}>
              <InputField
                onChange={handleOnChange("name")}
                value={name}
                type="text"
                placeholder={'Dataset Name'}
                label={'Dataset Name'}
                error={error.name}
              />
            </div>

            {type === 'aws' && <div style={{ marginTop: '20px' }}>
              <InputField
                onChange={handleOnChange("path")}
                value={path}
                type="text"
                placeholder={'s3://...'}
                label={'Path'}
                error={error.path}
              />
            </div>}


            {type === 'local' && <><div style={{ fontSize: '12px', marginBottom: '10px' }}>File</div>
              <Upload {...uploadProps} maxCount={1} multiple={false}>
                <Button className="__extra_btn_cls_upload" icon={<InboxOutlined />}>Select File to upload</Button>
              </Upload>
            </>
            }<div
            style={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: "10px" }}>Relations:</span>
            <Switch
              onChange={handleToggle}
              checkedChildren="On"
              unCheckedChildren="Off"
              defaultChecked={true}
            />
          </div>
            <Button type="primary" className="__extra_btn_cls" loading={loading} onClick={handleUpload}>Register</Button>

          </div>
        </div>
      </Modal>
    </>
  );
};

export default UploadDataMart;

UploadDataMart.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};
